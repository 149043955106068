export default {

  fields: {
    username: 'Email',
    password: 'Password',
  },
  buttons: {
    signin: 'Sign in',
    not_registered: 'Not registered yet?',
    having_questions: 'Have questions? Feel free to <a href="mailto:members@starlingminds.com?subject=Feedback%20and%20questions" target="_blank">contact us</a> any time',
    forgotten_password: 'Forgotten password?',
  },
  error: {
    message: 'Invalid username or password',
  },
};
